.your-tests-container {
  .your-tests-folders-container {
    overflow-x: hidden;
    height: 100%;
  }

  .migrated-tests-container {
    margin-top: 5px;
    cursor: pointer;

    .migrated-book-node {
      position: relative;
      padding: 5px;
      width: 100%;
      background-color: #f8f8f8;
      color: #4d4d4d;
      font-size: 14px;
      margin-bottom: 2px;

      span {
        margin-left: 9px;
      }

      .fa-caret-right,
      .fa-caret-down {
        margin-left: 5px;
      }
    }

    .migrated-tests-books {
      padding-left: 18px;
    }

    .migrated-tests {
      padding-left: 22px;

      .view-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        position: absolute;
        border-radius: 3px;
        right: 2px;
        top: 3px;
        height: 24px;

        &:hover {
          border-color: #007fa9;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        i {
          height: 16px;
        }

        &.selected {
          background-color: #007fa9;
          color: #fff;
        }
      }
    }
  }

  .your-tests-treeview {
    &.treeview > ul {
      padding-top: 0;
    }

    .your-tests-tree-node {
      display: flex;
      align-items: start;
      padding: 10px 65px 10px 25px;
      background-color: #f8f8f8;
      font-size: 14px;
      color: #4d4d4d;
      position: relative;
      line-height: 1em;
      margin-top: 2px;

      .caret-container {
        cursor: pointer;
      }

      .tree-node-text {
        padding-left: 8px;
      }

      .tree-node-action-buttons-container {
        position: absolute;
        right: 2px;
        top: 2px;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        background-color: transparent;
        width: 68px;

        .action-button {
          border-radius: 3px;
          background-color: #f8f8f8;
          border: none;
          padding: 8px;

          &:hover {
            border-color: #007fa9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }

          &.selected {
            border-color: #007fa9;
            background-color: #007fa9;
          }

          &.highlight {
            border-color: #007fa9;
            background-color: #007fa9;
          }
        }
      }
    }
  }
}
