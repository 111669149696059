.question-banks-treeview {
  ul {
    list-style: none;
    padding-left: 1em;
  }

  img {
    max-width: 100%;
  }
}

.question-banks-treeview > ul {
  box-sizing: border-box;
  padding: 5px 0px;
}

.questionaddforquestionbank {
  cursor: pointer;
  border-radius: 3px;
  background-color: #f8f8f8;
  border: none;

  position: relative;
}

.questionaddforquestionbank:hover {
  border-color: #007fa9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.questionaddforquestionbank.selected {
  border-color: #007fa9;
  background-color: #007fa9;
}
.darker-icon {
  text-shadow: 0 0 0 #000;
}

.question-banks-tree-node {
  padding: 10px;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #4d4d4d;
  position: relative;
  margin-top: 2px;

  .caret-container {
    cursor: pointer;
  }

  .tree-node-text {
    padding-left: 8px;
  }

  &.clicked {
    background-color: #007fa9;
    color: white;
  }
}
