#folders-tests {
  max-height: 100%;
}

.folders-tree-node {
  display: flex;
  align-items: start;
  padding: 10px;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #4d4d4d;
  position: relative;
  line-height: 1em;
  margin-top: 2px;

  .caret-container {
    cursor: pointer;
  }

  .tree-node-text {
    padding-left: 8px;
  }

  &.clicked {
    background-color: #007fa9;
    color: white;
  }
}
