.questionadd {
  position: absolute;
  top: 2px;
  cursor: pointer;
  border-radius: 3px;
  background-color: #f8f8f8;
  border: none;
  padding: 8px;
  right: 2px;
}

.questionadd:hover {
  border-color: #007fa9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.questionadd.selected {
  border-color: #007fa9;
  background-color: #007fa9;
}

.darker-icon {
  text-shadow: 0 0 0 #000;
}

.question-folder-tree-node {
  display: flex;
  align-items: start;
  padding: 10px 35px 10px 25px;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #4d4d4d;
  position: relative;
  margin-top: 2px;

  .caret-container {
    cursor: pointer;
  }

  .tree-node-text {
    padding-left: 8px;
  }

  .tree-node-action-buttons-container {
    position: absolute;
    right: 2px;
    top: 2px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    background-color: transparent;
    width: 32px;

    .action-button {
      border-radius: 3px;
      background-color: #f8f8f8;
      border: none;
      padding: 8px;

      &:hover {
        border-color: #007fa9;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }

      &.selected {
        border-color: #007fa9;
        background-color: #007fa9;
      }
    }
  }

  &.clicked .tree-node-action-buttons-container {
    .action-button {
      background: transparent;
      color: #fff;

      &:hover {
        box-shadow: 0 2px 4px rgba(255, 255, 255, 0.8);
      }

      &.selected {
        background-color: #f8f8f8;
        color: #000;
      }
    }
  }
}

.lazy-load-spinner .spinner-border {
  border-width: 4px;
}
