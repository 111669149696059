.randomize-test-modal {
  .modal-dialog {
    width: 340px;
  }

  hr {
    color: #8195a9;
  }

  .version-select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    select {
      width: 140px;
    }
  }
}
